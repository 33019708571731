<template>
  <v-app>
    <PayHeader />
    <v-main>
      <v-content>
        <v-container v-show="!storage.overlay" fill-height class="v-container">
          <router-view></router-view>
        </v-container>
        <v-container v-show="storage.overlay" fill-height class="v-container">
          <v-layout style="display: flex; min-height: 500px;">
            <v-row
              justify="center"
              align="center"
            >
              <v-progress-circular
                :size="100"
                :width="10"
                color="green"
                indeterminate
              />
            </v-row>
          </v-layout>
        </v-container>
      </v-content>
    </v-main>
    <PayFooter />
  </v-app>
</template>

<script>
import PayHeader from '@/components/PayHeader.vue'
import PayFooter from '@/components/PayFooter.vue'
import { provide, reactive, ref } from 'vue'
import router from '@/utils/router'

export default {
  name: 'PayApp',

  components: {
    PayHeader: PayHeader,
    PayFooter: PayFooter
  },
  setup () {
    const storage = reactive({
      overlay: ref(true),
      showBack: ref(false)
    })

    const setStorage = (key, val) => {
      console.log(key, val)
      if (val instanceof Object) {
        storage[key] = val
      } else {
        storage[key] = ref(val)
      }
    }

    const increase = () => {
      storage.number_of_vouchers++
      storage.amount = storage.provider.limits.min * storage.number_of_vouchers
    }

    const actualize = () => {
      storage.amount = storage.provider.limits.min * storage.number_of_vouchers
    }

    const decrease = () => {
      if (storage.number_of_vouchers > 1) {
        storage.number_of_vouchers--
        storage.amount = storage.provider.limits.min * storage.number_of_vouchers
      }
    }

    const prevHistory = () => {
      if (router.currentRoute.value.path.includes('/pay/') && storage.provider.type_id === 2) {
        storage.amount = 0
      }
      router.go(-1)
      if (router.currentRoute.value.path.includes('/provider/')) {
        router.push('/')
      }
    }

    const setToRoute = (Route) => {
      setStorage('overlay', true)
      router.push({ name: Route })
    }

    provide('storage', { storage, setStorage })
    provide('history', { prevHistory, setToRoute })
    provide('voucher', { increase, actualize, decrease })

    return {
      storage
    }
  }
}
</script>
